const env = {
   // url: 'http://localhost:8000/',
    //api: 'http://localhost:8000/api/v1/',
     url: 'https://api.safety-bauminas.innovareti.com.br/',
     api: 'https://api.safety-bauminas.innovareti.com.br/api/v1/',
    applicationName: 'BAUMINAS',
    applicationFooter: 'BAUMINAS',
    chartBarBackgroundColor: '#2a4a80',
    title: 'Bauminas'
}

export default env
