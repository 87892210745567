export const state = {
    layoutType: 'vertical',
    layoutWidth: 'fluid',
    sidebarSize: 'lg',
    topbar: 'light',
    mode: 'light',
    position: 'fixed',
    sidebarView: 'default',
    sidebarColor: 'dark',
    sidebarImage: 'none',
    preloader: 'disable',
    visibility: 'show'
};

export const mutations = {
    CHANGE_SIDEBAR_TYPE(state, sidebarSize) {
        state.sidebarSize = sidebarSize;
    },
    CHANGE_TOPBAR(state, topbar) {
        state.topbar = topbar;
    },
    CHANGE_MODE(state, mode) {
        state.mode = mode;
    },
    CHANGE_SIDEBAR_IMAGE(state, sidebarImage) {
        state.sidebarImage = sidebarImage;
    },
    CHANGE_VISIBILITY(state, visibility) {
        state.visibility = visibility;
    },
};

export const actions = {
    changeTopbar({commit}, {topbar}) {
        commit('CHANGE_TOPBAR', topbar);
    },

    changeMode({commit}, {mode}) {
        commit('CHANGE_MODE', mode);
    },

    changeSidebarColor({commit}, {sidebarColor}) {
        commit('CHANGE_SIDEBAR_COLOR', sidebarColor);
    },

    changeSidebarImage({commit}, {sidebarImage}) {
        commit('CHANGE_SIDEBAR_IMAGE', sidebarImage);
    },

    changeVisibility({commit}, {visibility}) {
        commit('CHANGE_VISIBILITY', visibility);
    }
};
