import axios from "@/axios";
import Router from "@/router";
import env from '@/env';

export const state = {
    currentUser: {}
}

export const mutations = {
    setCurrentUser(state, newValue) {
        state.currentUser = newValue
    }
}

export const getters = {
    loggedIn(state) {
        return !!state.currentUser
    }
}

export const actions = {
    // eslint-disable-next-line no-unused-vars
    init({state, dispatch}) {
        dispatch('validate')
    },

    setCurrentUser({commit, dispatch}, token, token_expire) {
        localStorage.setItem('token', token)
        localStorage.setItem('token_expire', token_expire)
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('token')
        commit('setCurrentUser', token, token_expire)
        dispatch('initialize')
    },

    initialize({commit}) {
        const token = localStorage.getItem('token')

        if (!token) {
            return this.dispatch('reset')
        }

        axios.post(env.api + 'me', {
            token: token
        })
            .then(response => {
                commit('setCurrentUser', response.data)
                localStorage.setItem('token_expire', response.data.token_expires_at);
                Router.push({name: 'dashboard'});
            })
            .catch(error => {
                console.error(error)
                localStorage.removeItem('token');
                Router.push({name: 'login'});
            });
    },

    logout({commit}) {
        axios
            .post(this.getters.api + 'logout')
            .then(() => {
                localStorage.removeItem('token');
                commit('setCurrentUser', null);
            })
            .catch(error => {
                console.error(error)
                // this.$message("Erro", "Erro ao realizar logout", "error");
            });
    },
}