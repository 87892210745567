// import store from "@/state/store";

export default [
    {
        path: '/',
        redirect: {
            name: 'dashboard'
        }
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/account/login.vue"),
        meta: {
            title: "Login",
            // beforeResolve(routeTo, routeFrom, next) {
            //     if (store.getters["auth/loggedIn"]) {
            //         next({name: "dashboard"});
            //     } else {
            //         next();
            //     }
            // },
        },
    },
    {
        path: "/esqueceu-senha",
        name: "forgot-password",
        component: () => import("@/views/account/forgot-password.vue"),
        meta: {
            title: "Esqueceu a Senha",
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("@/views/account/logout.vue"),
        meta: {
            title: "Logout",
        },
    },
    {
        path: "/",
        name: "dashboard",
        meta: {
            title: "Dashboard",
            authRequired: true,
        },
        component: () => import("@/views/dashboard/index.vue"),
    },
    {
        path: "/usuarios",
        name: "users",
        meta: {
            title: "Usuários",
            authRequired: true,
        },
        component: () => import("@/views/users/users.vue"),
    },
    {
        path: "/meu-perfil",
        name: "my-profile",
        meta: {
            title: "Meu Perfil",
            authRequired: true,
        },
        component: () => import("@/views/users/my-profile.vue"),
    },
    {
        path: "/ocorrencias",
        name: "occurrences",
        meta: {
            title: "Ocorrências",
            authRequired: true,
        },
        component: () => import("@/views/occurrence/occurrence.vue"),
    },
    {
        path: "/buscas",
        name: "searches",
        meta: {
            title: "Buscas",
            authRequired: true,
        },
        component: () => import("@/views/search/search.vue"),
    },
];