import {createWebHistory, createRouter} from "vue-router";
import routes from './routes';
import env from "@/env";
// import {ACCESS_BY_LEVEL} from "@/components/composables/variables";
import store from "@/state/store";

const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {top: 0, left: 0};
        }
    },
});

router.beforeEach(async (routeTo, routeFrom, next) => {

    store.commit('api/getValues', {})

    const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

    if (!authRequired) {
        return next();
    }

    if (localStorage.getItem('token') == null)  {
        next({name: 'login'});
    }

    next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
    try {
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        if (args.length) {
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    resolve();
                }
            });
        }
    } catch (error) {
        return;
    }
    document.title = routeTo.meta.title + ' | ' + env.title;
    next();
});

export default router;
